import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmModal from 'components/Modals/Confirm';
import CreateClientPayout from '../CreateClientPayout';

const CreateCounterparty = () => {
  const { merchantId } = useParams();
  const [confirm, setConfirm] = useState(false);
  const [counterpartyId, setCounterpartyId] = useState('');
  const success = useCallback((value: string) => {
    setCounterpartyId(value);
    setConfirm(true);
  }, []);
  return (
    <>
      <ConfirmModal
        title={`Counterparty created (${counterpartyId})`}
        visible={confirm}
        handleClose={() => {
          setConfirm(false);
        }}
      />
      <CreateClientPayout merchantId={merchantId} onSuccess={success} />
    </>
  );
};

export default CreateCounterparty;
