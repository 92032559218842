const ibanTable = [
  {
    start: 1100,
    end: 1199,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 1200,
    end: 1399,
    ibanId: 120,
    bic: 'DABASESX',
    bankName: 'Danske Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 1400,
    end: 2099,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 2300,
    end: 2399,
    ibanId: 230,
    bic: 'AABASESS',
    bankName: '\u00C5landsbanken',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 2400,
    end: 2499,
    ibanId: 120,
    bic: 'DABASESX',
    bankName: 'Danske Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 3000,
    end: 3299,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 3300,
    end: 3300,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 2,
    comment: 1,
    method: 2,
    minumum: 10,
    maximum: 10
  },
  {
    start: 3301,
    end: 3399,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 3400,
    end: 3409,
    ibanId: 902,
    bic: 'ELLFSESS',
    bankName: 'L\u00E4nsf\u00F6rs\u00E4kringar Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 3410,
    end: 3781,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 3782,
    end: 3782,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 2,
    comment: 1,
    method: -1,
    minumum: 7,
    maximum: 10
  },
  {
    start: 3783,
    end: 3999,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 4000,
    end: 4999,
    ibanId: 300,
    bic: 'NDEASESS',
    bankName: 'Nordea',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 5000,
    end: 5999,
    ibanId: 500,
    bic: 'ESSESESS',
    bankName: 'SEB',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 6000,
    end: 6999,
    ibanId: 600,
    bic: 'HANDSESS',
    bankName: 'Handelsbanken',
    type: 2,
    comment: 2,
    method: 2,
    minumum: 7,
    maximum: 9
  },
  {
    start: 7000,
    end: 7999,
    ibanId: 800,
    bic: 'SWEDSESS',
    bankName: 'Swedbank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 8000,
    end: 8999,
    ibanId: 800,
    bic: 'SWEDSESS',
    bankName: 'Swedbank',
    type: 2,
    comment: 3,
    method: 3,
    minumum: 7,
    maximum: 10
  },
  {
    start: 9020,
    end: 9029,
    ibanId: 902,
    bic: 'ELLFSESS',
    bankName: 'L\u00E4nsf\u00F6rs\u00E4kringar Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9040,
    end: 9049,
    ibanId: 904,
    bic: 'CITISESX',
    bankName: 'Citibank (filial)',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9060,
    end: 9069,
    ibanId: 902,
    bic: 'ELLFSESS',
    bankName: 'L\u00E4nsf\u00F6rs\u00E4kringar Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9070,
    end: 9079,
    ibanId: 907,
    bic: 'FEMAMTMT',
    bankName: 'Multitude Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9100,
    end: 9109,
    ibanId: 910,
    bic: 'NNSESES1',
    bankName: 'Nordnet Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9120,
    end: 9124,
    ibanId: 500,
    bic: 'ESSESESS',
    bankName: 'SEB',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9130,
    end: 9149,
    ibanId: 500,
    bic: 'ESSESESS',
    bankName: 'SEB',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9150,
    end: 9169,
    ibanId: 915,
    bic: 'SKIASESS',
    bankName: 'Skandiabanken',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9170,
    end: 9179,
    ibanId: 917,
    bic: 'IKANSE21',
    bankName: 'Ikanobanken',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9180,
    end: 9189,
    ibanId: 120,
    bic: 'DABASESX',
    bankName: 'Danske Bank',
    type: 2,
    comment: 1,
    method: -1,
    minumum: 7,
    maximum: 10
  },
  {
    start: 9190,
    end: 9199,
    ibanId: 919,
    bic: 'DNBASESX',
    bankName: 'DnB Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9230,
    end: 9239,
    ibanId: 923,
    bic: 'MARGSESS',
    bankName: 'Marginamaximum Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9250,
    end: 9259,
    ibanId: 925,
    bic: 'SBAVSESS',
    bankName: 'SBAB Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9260,
    end: 9269,
    ibanId: 919,
    bic: 'DNBASESX',
    bankName: 'DnB Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9270,
    end: 9279,
    ibanId: 927,
    bic: 'IBCASES1',
    bankName: 'ICA Banken',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9280,
    end: 9289,
    ibanId: 928,
    bic: 'RESUSE21',
    bankName: 'Resurs Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9300,
    end: 9349,
    ibanId: 930,
    bic: 'SWEDSESS',
    bankName: 'Swedbank',
    type: 2,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 10
  },
  {
    start: 9390,
    end: 9399,
    ibanId: 939,
    bic: 'LAHYSESS',
    bankName: 'Landshypotek',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9400,
    end: 9449,
    ibanId: 940,
    bic: 'FORXSES1',
    bankName: 'Forex Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9460,
    end: 9469,
    ibanId: 946,
    bic: 'BSNOSESS',
    bankName: 'Santander Consumer Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9470,
    end: 9479,
    ibanId: 947,
    bic: 'FTSBSESS',
    bankName: 'BNP Paribas',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9500,
    end: 9549,
    ibanId: 950,
    bic: 'NDEASESS',
    bankName: 'Nordea (Plusgirot)',
    type: 2,
    comment: 3,
    method: -1,
    minumum: 7,
    maximum: 10
  },
  {
    start: 9550,
    end: 9569,
    ibanId: 955,
    bic: 'AVANSES1',
    bankName: 'Avanza Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9570,
    end: 9579,
    ibanId: 957,
    bic: 'SPSDSE23',
    bankName: 'Sparbanken Syd',
    type: 2,
    comment: 1,
    method: 2,
    minumum: 5,
    maximum: 10
  },
  {
    start: 9580,
    end: 9589,
    ibanId: 958,
    bic: 'BMPBSESS',
    bankName: 'AION Bank',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9590,
    end: 9599,
    ibanId: 959,
    bic: 'ERPFSES2',
    bankName: 'Erik Penser Bank AB',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9630,
    end: 9639,
    ibanId: 963,
    bic: 'LOSADKKK',
    bankName: 'L\u00E5n & Spar Bank A/S, filial',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9640,
    end: 9649,
    ibanId: 964,
    bic: 'NOFBSESS',
    bankName: 'NOBA Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9660,
    end: 9669,
    ibanId: 966,
    bic: 'SVEASES1',
    bankName: 'Svea Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9670,
    end: 9679,
    ibanId: 967,
    bic: 'JAKMSE22',
    bankName: 'JAK Medlemsbank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9680,
    end: 9689,
    ibanId: 968,
    bic: 'BSTPSESS',
    bankName: 'Bluestep Finans AB',
    type: 1,
    comment: 1,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9700,
    end: 9709,
    ibanId: 970,
    bic: 'EKMLSE21',
    bankName: 'Ekobanken',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9710,
    end: 9719,
    ibanId: 971,
    bic: 'LUNADK2B',
    bankName: 'Lunar Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9750,
    end: 9759,
    ibanId: 975,
    bic: 'NOHLSESS',
    bankName: 'Northmill Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9780,
    end: 9789,
    ibanId: 978,
    bic: 'KLRNSESS',
    bankName: 'Klarna Bank',
    type: 1,
    comment: 2,
    method: 1,
    minumum: 7,
    maximum: 7
  },
  {
    start: 9960,
    end: 9969,
    ibanId: 950,
    bic: 'NDEASESS',
    bankName: 'Nordea (Plusgirot)',
    type: 2,
    comment: 3,
    method: -1,
    minumum: 7,
    maximum: 10
  }
];

const findBankByIbanId = (bankCode: string) => {
  const code = Number.parseInt(bankCode, 10);
  const bank = ibanTable.filter((bank) => bank.ibanId === code);
  return bank ?? null;
};
// SE8030000000041351300039
const checkClearingCode = (iban: string) => {
  iban = iban.replaceAll(/\s+/g, '');
  const substring = iban.slice(7);
  const match = substring.match(/0+(\d+)/);
  return match && match[1].length >= 4 ? match[1].slice(0, 4) : null;
};

const bankMethod1 = (iban: string, bankConfiguration: any) => {
  iban = iban.replaceAll(/\s+/g, '');
  const clearingCode = checkClearingCode(iban);
  const accountNumber = iban.slice(-bankConfiguration?.maximum ?? 7);
  return [clearingCode, accountNumber];
};

const bankMethod2 = (iban: string, bankConfiguration: any) => {
  iban = iban.replaceAll(/\s+/g, '');
  if (bankConfiguration == null) return [null, null];
  const clearingCode = bankConfiguration.start.toString();
  const accountNumber = iban.slice(-bankConfiguration.maximum);
  return [clearingCode, accountNumber];
};

const _bankMethod3 = (iban: string, bankConfiguration: any) => {
  iban = iban.replaceAll(/\s+/g, '');
  const clearingCode = checkClearingCode(iban);
  const accountNumber =
    bankConfiguration == null
      ? clearingCode
        ? iban.slice(iban.indexOf(clearingCode) + 4)
        : '0'
      : iban.slice(-bankConfiguration.maximum);
  return [clearingCode, accountNumber];
};

export const parseIban = (iban: string) => {
  //const countryCode = iban.slice(0, 2);
  //const checkDigits = iban.slice(2, 4);
  const bankCode = iban.slice(4, 7);
  //const accountNumber = iban.slice(7);
  const bankConfigurations = findBankByIbanId(bankCode);
  if (bankConfigurations === null) {
    return bankMethod1(iban, null);
  } else {
    let bankConfiguration = bankConfigurations[0];
    if (bankConfigurations.length > 1) {
      const clearingCode = checkClearingCode(iban);
      if (clearingCode != null) {
        const finder = bankConfigurations.find(
          (value) => Number.parseInt(clearingCode) >= value.start && Number.parseInt(clearingCode) <= value.end
        );
        if (finder) {
          bankConfiguration = finder;
        } else {
          if (bankCode === '300') {
            bankConfiguration = bankConfigurations.find((value) => value.method === 2)!;
          }
        }
      }
    }
    switch (bankConfiguration?.method) {
      case 1: {
        return bankMethod1(iban, bankConfiguration);
      }
      case 2: {
        return bankMethod2(iban, bankConfiguration);
      }
      case 3: {
        return bankMethod1(iban, bankConfiguration);
      }
      default: {
        return bankMethod1(iban, bankConfiguration);
      }
    }
  }
};
