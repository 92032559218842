import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import InfoModal from 'components/Modals/Info';
import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { ADMIN_CUSTOMERS_QUERY, REDASH_API } from 'shared-components/queries/graphql';

import PidDetails from './pidDetails';
//import { BaseProps } from 'shared-components/utils/types';

type SearchBarProps = {
  onSearch?: (value: string) => void;
};
const SearchBar = ({ onSearch }: SearchBarProps) => {
  const timeoutHandler = useRef<any>(null);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);
      timeoutHandler.current = setTimeout(() => {
        onSearch?.call(this, searchValue);
      }, 500);
    },
    [onSearch]
  );
  return <Form.Control type="pid" placeholder="pid number" onChange={onChangeHandler} />;
};

//type CompliancePageType = BaseProps & {};
const CompliancePage = () => {
  const [hits, setHits] = useState([]);
  const [searchPid, setSearchPid] = useState('');
  const [pid, setPid] = useState<string | undefined>();
  const [customerLocalData, setCustomerLocalData] = useState([]);
  const [customerRedashData, setCustomerRedashData] = useState([]);

  const pids = useMemo(() => {
    // Create a map of existing data based on "Person ID"
    const existingDataMap = new Map(
      customerRedashData.map((record: any) => {
        const cleanedPersonId = record['Person ID'].replaceAll(/<[^>]*>/g, '');
        return [cleanedPersonId, record];
      })
    );
    // Flatten and insert customerLocalData using a for...of loop
    for (const record of customerLocalData as any[]) {
      const flattenedRecord = {
        last_name: record.kyc.person?.surname ?? '',
        first_name: record.kyc.person?.forename ?? '',
        'Person ID': record.personId
      };
      // Overwrite or add the new record based on "Person ID"
      existingDataMap.set(flattenedRecord['Person ID'], flattenedRecord);
    }

    // Convert the map back to an array of records
    const combinedData = [...existingDataMap.values()];

    // If searchPid is provided, filter the combinedData based on it

    const filteredData = combinedData.filter((record) => {
      return (
        record['Person ID'].includes(searchPid) ||
        record.last_name?.toLowerCase().includes(searchPid?.toLowerCase()) ||
        record.first_name?.toLowerCase().includes(searchPid?.toLowerCase())
      );
    });
    return searchPid.length == 0 ? combinedData : filteredData;
  }, [customerLocalData, customerRedashData, searchPid]);

  const limithit = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'limithit',
    data: {
      query: REDASH_API('limithit')
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      setHits(rows);
    }
  });

  const customers = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'customers',
    data: {
      query: ADMIN_CUSTOMERS_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const list = data?.edges?.map((value: any) => {
        const current = value.node;
        try {
          current.kyc = JSON.parse(current.kyc);
          current.merchant = current.merchant?.edges?.map((value: any) => value.node);
        } catch {
          //
        }
        return current;
      });
      setCustomerLocalData(list);
    }
  });

  const pidsearch = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'pidsearch',
    data: {
      query: REDASH_API('pidsearch')
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      setCustomerRedashData(rows);
    }
  });

  useEffect(() => {
    customers.execute();
    pidsearch.execute({
      variables: {
        queryId: '45',
        parameters: JSON.stringify({
          search: '@'
        })
      }
    });

    limithit.execute({
      variables: {
        queryId: '44',
        parameters: JSON.stringify({})
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <InfoModal
        title={'Person ID details'}
        visible={pid != null}
        handleClose={() => setPid(undefined)}
        onOk={() => setPid(undefined)}
        size="xl"
      >
        {pid != undefined && <PidDetails pid={pid} />}
      </InfoModal>
      <Row>
        <Col>
          <DataTable
            title={
              <Container fluid>
                <Row>
                  <Col>
                    <center>
                      <h3>Person ID search</h3>
                    </center>
                  </Col>
                  <Col md={6}>
                    <SearchBar
                      onSearch={(value) => {
                        let searchString = '';
                        if (value != '') {
                          searchString = value;
                        }
                        setSearchPid(searchString);
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            }
            columns={[
              {
                name: 'Person ID',
                selector: (row: any) =>
                  row?.['Person ID'] == null ? (
                    ''
                  ) : (
                    <Button
                      style={{ width: '120px' }}
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        setPid(row?.['Person ID'].replaceAll(/<[^>]*>/g, ''));
                      }}
                    >
                      {row?.['Person ID'].replaceAll(/<[^>]*>/g, '')}
                    </Button>
                  )
              },
              {
                name: 'First name',
                selector: (row: any) => (row?.['first_name'] == null ? '' : row?.['first_name'])
              },
              {
                name: 'Last name',
                selector: (row: any) => (row?.['last_name'] == null ? '' : row?.['last_name'])
              }
            ]}
            data={pids}
            pagination
          ></DataTable>
        </Col>
        <Col>
          <DataTable
            title={
              <center>
                <h3>Last 30 days with 150k hit date</h3>
              </center>
            }
            columns={[
              {
                name: 'Person ID',
                selector: (row: any) => (
                  <Button
                    style={{ width: '120px' }}
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      setPid(row?.['Person ID'].replaceAll(/<[^>]*>/g, ''));
                    }}
                  >
                    {row?.['Person ID'].replaceAll(/<[^>]*>/g, '')}
                  </Button>
                )
              },
              {
                name: 'total',
                selector: (row: any) => row?.['total sum']
              },
              {
                name: 'Hit date',
                selector: (row: any) => row?.['150k hit date'].replace('T', ' ')
              },

              {
                name: 'kivra status',
                selector: (row: any) => row?.['kivra status']
              }
            ]}
            data={hits}
            pagination
          ></DataTable>
          <br />
        </Col>
      </Row>
    </>
  );
};
export default CompliancePage;
