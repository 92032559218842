import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import clsx from 'clsx';

import { MERCHANTS_ASSOCIATED, SWITCH_ACCOUNT } from 'shared-components/queries/graphql';
import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';

import style from './style.module.scss';

const MerchantSwitcher = () => {
  const merchantRef = useRef<HTMLSelectElement>(null);
  const [merchantList, setMerchantList] = useState<any>([]);
  const merchantInfo = useMerchantInfo();

  const switchAccountsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'switchAccount',
    data: {
      query: SWITCH_ACCOUNT
    },
    auto: false,
    onResponse: (_: any) => {
      merchantInfo.refresh();
    }
  });

  const merchantsAssociatedQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'merchants',
    data: {
      query: MERCHANTS_ASSOCIATED
    },
    auto: false,
    onResponse: (data: any) => {
      const merchants = data?.edges?.map((value: any) => {
        return value.node;
      });

      setMerchantList(merchants);
    }
  });

  const handleMerchantSwitch = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const merchantId: any = event.currentTarget.value;
      switchAccountsQuery.execute({
        variables: {
          merchantId: atob(merchantId).split(':')[1]
        }
      });
    },
    [switchAccountsQuery]
  );

  useEffect(() => {
    merchantsAssociatedQuery.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Form.Floating className={clsx(style.floatingItem, 'mb-3')}>
        <Form.Select onChange={handleMerchantSwitch} aria-label="" ref={merchantRef} className={style.formItem}>
          <option>Select current merchant</option>
          {Object.keys(merchantList).map((value: any, key) => {
            const id = merchantList[value]['id'];
            return (
              <option key={key} value={merchantList[value]['id']} selected={merchantInfo.id === atob(id).split(':')[1]}>
                {merchantList[value]['name']}
              </option>
            );
          })}
        </Form.Select>
        <label className={style.label} htmlFor={'merchantList'}>
          {'Counterparty list'}
        </label>
      </Form.Floating>
    </>
  );
};

export default MerchantSwitcher;
