import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import useSWQuery from 'shared-components/hooks/useSWQuery';
import { environment, Method, Service } from 'shared-components/configuration';
import { useAuthEID } from 'shared-components/providers/AuthEIDProvider';
import { MERCHANT_INFO_QUERY } from 'shared-components/queries/graphql';
import { useApp } from 'shared-components/providers/AppProvider';
import { MerchantInfo } from '../types/merchantInfoType';
import { BaseProps } from '../utils/types';

import { useGraphQL } from './GraphQLProvider';

const initialValues: MerchantInfo = {
  id: '',
  email: '',
  iban: '',
  bban: '',
  accountId: '',
  orgNumber: '',
  country: '',
  name: '',
  counterpartyId: '',
  approved: false,
  fee: 0,
  fxFee: 0,
  fixedFee: 0,
  withdrawFee: 0,
  payoutFee: 0,
  depositFee: 0,
  webhook: '',
  public_keys: undefined,
  refresh: () => {
    //
  }
};
const MerchantInfoContext = createContext<MerchantInfo>(initialValues);

export const useMerchantInfo = () => {
  const context = useContext(MerchantInfoContext);
  if (!context) {
    throw new Error('Context not available');
  }
  return context;
};
export const MerchantInfoProvider = ({ children }: BaseProps) => {
  const [data, setData] = useState<MerchantInfo>(initialValues);
  const {
    values: { loggedIn }
  } = useApp();
  const { token } = useAuthEID();
  const { authorized } = useGraphQL();

  //const { userData } = useApp();
  const merchantInfo = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'merchantInfo',
    data: {
      access_token: token,
      query: MERCHANT_INFO_QUERY,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      setData((old: MerchantInfo) => ({ ...old, ...data, refresh: refreshMerchantInfo }));
    }
  });

  const refreshMerchantInfo = useCallback(() => {
    merchantInfo.execute();
  }, [merchantInfo]);

  useEffect(() => {
    if (authorized && loggedIn && !environment.startsWith('admin')) {
      merchantInfo.execute();
    } else {
      setData(initialValues);
    }
  }, [authorized, loggedIn, environment]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MerchantInfoContext.Provider value={data}>{children}</MerchantInfoContext.Provider>
    </>
  );
};
