import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

import InfoModal from 'components/Modals/Info';
import { AdminPrefix, AdminUrl, MerchantPrefix, Method, Service, environment } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { ADMIN_CUSTOMERS_QUERY, CUSTOMERS_BY_MERCHANT_QUERY } from 'shared-components/queries/graphql';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';

type SearchBarProps = {
  onSearch?: (value: string) => void;
};
const SearchBar = ({ onSearch }: SearchBarProps) => {
  const timeoutHandler = useRef<any>(null);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);
      timeoutHandler.current = setTimeout(() => {
        onSearch?.call(this, searchValue);
      }, 1000);
    },
    [onSearch]
  );
  return (
    <FloatingLabel controlId="pid_entry" label="Search pid" className="mb-3">
      <Form.Control size="sm" type="pid" placeholder="pid number" onChange={onChangeHandler} />
    </FloatingLabel>
  );
};

const Customers = () => {
  const prefix = useMemo(() => {
    return environment.startsWith('admin') ? AdminPrefix : MerchantPrefix;
  }, []);

  const [data, setData] = useState<any>([]);
  const [kycData, setKycData] = useState<any>({});
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const [pidSearch, setPidSearch] = useState<string>('');
  const [totalRows, setTotalRows] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const navigator = useNavigate();
  const { name } = useMerchantInfo();

  const customers = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'customers',
    data: {
      query: environment.startsWith('admin') ? ADMIN_CUSTOMERS_QUERY : CUSTOMERS_BY_MERCHANT_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const list = data?.edges?.map((value: any) => {
        const current = value.node;
        try {
          current.kyc = JSON.parse(current.kyc);
          current.merchant = current.merchant?.edges?.map((value: any) => value.node);
        } catch {
          //
        }
        return current;
      });
      setData(list);
    }
  });

  useEffect(() => {
    setTotalRows(data.totalCount);
    setLoading(false);
  }, [data]);

  useEffect(() => {
    handlePageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pidSearch]);

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(data.totalCount / perPage);
    if (page === 1) {
      customers.execute({ variables: { pid: pidSearch, first: perPage } });
    } else if (page >= totalPages) {
      customers.execute({ variables: { pid: pidSearch, last: perPage } });
    } else if (page > currentPage) {
      customers.execute({ variables: { pid: pidSearch, first: perPage, after: data.pageInfo.endCursor } });
    } else if (page < currentPage) {
      customers.execute({ variables: { pid: pidSearch, last: perPage, before: data.pageInfo.startCursor } });
    } else {
      customers.execute({ variables: { pid: pidSearch, first: perPage, after: data.pageInfo.endCursor } });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  const columns: TableColumn<any>[] = [
    {
      name: 'Person ID',
      cell: (row) => (
        <Button
          size="sm"
          variant="dark"
          onClick={() => {
            setKycData(row.kyc);
            setDialogShow(true);
          }}
        >
          {row.personId}
        </Button>
      ),
      wrap: false,
      width: '160px'
    },
    {
      name: 'Merchants',
      cell: (row) => (
        <div style={{ marginTop: '0px', display: 'block' }}>
          {row.merchant?.map((value: any, key: any) => {
            return <div key={key}>{value.name}</div>;
          })}
        </div>
      ),
      wrap: false
    },
    {
      name: 'Actions',
      cell: (row) => (
        <center>
          <Button
            size="sm"
            onClick={() => {
              navigator(`${prefix}/${AdminUrl.RFQ_LIST}/${row.personId}`);
            }}
          >
            Show transactions
          </Button>
        </center>
      ),
      wrap: false
    }
  ];
  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <>
      <br />
      <h3>Customers pid & kyc info</h3>
      <InfoModal
        title={'KYC INFO'}
        visible={dialogShow}
        onOk={() => {
          setDialogShow(false);
        }}
        handleClose={() => {
          setDialogShow(false);
        }}
      >
        <center>
          {Object.keys(kycData!)?.map((val, key) => {
            const value = (kycData as any)[val] ?? '';
            if (typeof value === 'object') {
              return (
                <>
                  {Object.keys(value)?.map((val, key) => {
                    const intval = (value as any)[val] ?? '';
                    return (
                      <Col key={key} xs={12} md={6}>
                        <Row>
                          <Col xs={5} style={{ textAlign: 'left' }}>
                            {val}
                          </Col>
                          <Col xs={7} style={{ textAlign: 'left' }}>
                            {intval}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              );
            }
            return (
              <Col key={key} xs={12} md={6}>
                <Row>
                  <Col xs={5} style={{ textAlign: 'left' }}>
                    {val}
                  </Col>
                  <Col xs={7} style={{ textAlign: 'left' }}>
                    {value}
                  </Col>
                </Row>
              </Col>
            );
          })}
        </center>
      </InfoModal>
      <DataTable
        subHeader={true}
        subHeaderComponent={
          <SearchBar
            onSearch={(value) => {
              setPidSearch(value);
            }}
          />
        }
        responsive={true}
        highlightOnHover={true}
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationPerPage={30}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className="shadow p-3 mb-5 bg-white overflow-auto"
      />
    </>
  );
};
export default Customers;
