import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import {
  ADMIN_CUSTOMERS_QUERY,
  CLIENT_ACCOUNT_BALANCE,
  CLIENT_SUMMARY,
  REDASH_API,
  RFQ_QUERY
} from 'shared-components/queries/graphql';

import style from './style.module.scss';

type PidDetailsProps = {
  pid?: string;
};
const PidDetails = ({ pid }: PidDetailsProps) => {
  const [last30daysVolume, setLast30DaysVolume] = useState(0);
  const [transactionTotalVolume, setTransactionTotalVolume] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [kycDetails, setKycDetails] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [accountBalances, setAccountBalances] = useState([]);

  const handleKivraForm = useCallback(() => {
    console.log('KIVRA SEND!!!');
  }, []);

  const clientSummary = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'clientSummary',
    data: {
      query: CLIENT_SUMMARY,
      variables: {
        personId: pid
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const totalTransactions = result.total_transactions;
      const totalVolume = result.total_volume;
      const lastMonth = result.last_30_days;
      if (totalTransactions == 0 && totalVolume == null && lastMonth == null) {
        Qlast30days.execute({});
        QtransactionTotalVol.execute({});
        QtransactionCount.execute({});
      } else {
        setTransactionCount(totalTransactions ?? 0);
        setTransactionTotalVolume(totalVolume ?? 0);
        setLast30DaysVolume(lastMonth ?? 0);
      }
    }
  });

  const QaccountBalances = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'clientAccountBalances',
    data: {
      query: CLIENT_ACCOUNT_BALANCE,
      variables: {
        personId: pid
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const list = data?.edges
        ?.map((value: any) => {
          const current = value.node;
          const accounts = JSON.parse(current.accountsJson);
          return accounts.map((account: any) => ({
            actionDate: current.actionDate,
            bank: current.bank,
            ...account
          }));
        })
        .flat();
      if (list.length == 0) {
        QRaccountBalances.execute();
      } else {
        setAccountBalances(list);
      }
    }
  });

  const kycData = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'customers',
    data: {
      query: ADMIN_CUSTOMERS_QUERY,
      variables: {
        pid: pid
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const list = data?.edges?.map((value: any) => {
        const current = value.node;
        try {
          current.kyc = JSON.parse(current.kyc);
          current.merchant = current.merchant?.edges?.map((value: any) => value.node);
        } catch {
          //
        }
        return current;
      });
      if (list.length == 0 || list[0].kyc == undefined || list[0].kyc == null) {
        QkycDetails.execute({});
      } else {
        setKycDetails(list[0].kyc);
      }
    }
  });

  const rfqData = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'rfq',
    data: {
      query: RFQ_QUERY,
      variables: {
        status: 'SUCCESS',
        pid: pid
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const list = data?.edges
        ?.map((value: any) => {
          const current = value.node;

          const record = {
            signature_date: current.settledAt,
            bank_name: current.operationId.split('_')[1],
            amount: current.sekDeposit,
            status: current.status,
            description: current.txRef,
            transaction_status: ''
          };
          return record;
        })
        .flat();
      if (list.length == 0) {
        QtransactionsList.execute({});
      } else {
        setTransactionList(list);
      }
    }
  });

  const Qlast30days = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'last30days',
    data: {
      query: REDASH_API('last30days'),
      variables: {
        queryId: '46',
        parameters: JSON.stringify({
          id_number: pid
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      setLast30DaysVolume(rows[0]?.['last 30 days volume']);
    }
  });

  const QtransactionTotalVol = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'transactionTotalVolume',
    data: {
      query: REDASH_API('transactionTotalVolume'),
      variables: {
        queryId: '47',
        parameters: JSON.stringify({
          id_number: pid
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      setTransactionTotalVolume(rows[0]?.['total']);
    }
  });

  const QtransactionCount = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'transactionCount',
    data: {
      query: REDASH_API('transactionCount'),
      variables: {
        queryId: '48',
        parameters: JSON.stringify({
          id_number: pid
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      setTransactionCount(rows[0]?.['total']);
    }
  });

  const QkycDetails = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'kycDetails',
    data: {
      query: REDASH_API('kycDetails'),
      variables: {
        queryId: '17',
        parameters: JSON.stringify({
          id_number: pid
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      setKycDetails(rows[0]);
    }
  });

  const QtransactionsList = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'transactionsList',
    data: {
      query: REDASH_API('transactionsList'),
      variables: {
        queryId: '40',
        parameters: JSON.stringify({
          pid: pid
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows;
      console.log(rows);
      setTransactionList(rows);
    }
  });

  const QRaccountBalances = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'accountBalances',
    data: {
      query: REDASH_API('accountBalances'),
      variables: {
        queryId: '43',
        parameters: JSON.stringify({
          pid: pid
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = JSON.parse(data);
      const rows = result.query_result.data.rows as [];
      const balances: any =
        rows
          .filter((value: any) => value.iban != null)
          .map((account: any) => ({
            actionDate: account.created_at,
            iban: account.iban,
            name: account.name,
            balance: account.balance,
            currency: account.currency,
            bank: 'swedbank'
          })) ?? [];

      setAccountBalances(balances);
    }
  });

  useEffect(() => {
    clientSummary.execute();
    QaccountBalances.execute();
    kycData.execute();
    rfqData.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Card className={style.card}>
            <Card.Title>Last 30 days Volume</Card.Title>
            <Card.Text>{last30daysVolume}</Card.Text>
          </Card>
        </Col>
        <Col>
          <Card className={style.card}>
            <Card.Title>Transactions total volume</Card.Title>
            <Card.Text>{transactionTotalVolume}</Card.Text>
          </Card>
        </Col>
        <Col>
          <Card className={style.card}>
            <Card.Title>Transactions total count</Card.Title>
            <Card.Text>{transactionCount}</Card.Text>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className={style.card}>
            <Card.Title>KYC Details</Card.Title>
            <Card.Text>
              <center>
                {Object.keys(kycDetails!)?.map((val, key) => {
                  const value = (kycDetails as any)[val] ?? '';
                  if (typeof value === 'object') {
                    return (
                      <>
                        {Object.keys(value)?.map((val, key) => {
                          const intval = (value as any)[val] ?? '';
                          return (
                            <Col key={key} xs={12} md={12}>
                              <Row>
                                <Col style={{ textAlign: 'right' }}>{val}</Col>
                                <Col style={{ textAlign: 'left' }}>{intval}</Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </>
                    );
                  }
                  return (
                    <Col key={key} xs={12} md={6}>
                      <Row>
                        <Col style={{ textAlign: 'right' }}>{val}</Col>
                        <Col style={{ textAlign: 'left' }}>{value}</Col>
                      </Row>
                    </Col>
                  );
                })}
              </center>
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={style.card}>
            <Card.Title>Transaction list</Card.Title>
            <Card.Text>
              <DataTable
                columns={[
                  {
                    name: 'Signature date',
                    selector: (row: any) => (row?.['signature_date'] == null ? '' : row?.['signature_date'])
                  },
                  {
                    name: 'Bank name',
                    selector: (row: any) => (row?.['bank_name'] == null ? '' : row?.['bank_name'])
                  },
                  {
                    name: 'Amount',
                    selector: (row: any) => (row?.['amount'] == null ? '' : row?.['amount'])
                  },
                  {
                    name: 'Status',
                    selector: (row: any) =>
                      row?.['status'] == null ? '' : `${row?.['status']} (${row?.['transaction_status']}) `
                  },
                  {
                    name: 'Description',
                    selector: (row: any) => (row?.['description'] == null ? '' : row?.['description'])
                  }
                ]}
                data={transactionList}
                pagination
              ></DataTable>
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={style.card}>
            <Card.Title>Account balance</Card.Title>
            <Card.Text>
              <DataTable
                columns={[
                  {
                    name: 'Action date',
                    selector: (row: any) => (row?.['actionDate'] == null ? '' : row?.['actionDate'].replace('T', ' '))
                  },
                  {
                    name: 'bank',
                    selector: (row: any) => (row?.['bank'] == null ? '' : row?.['bank'])
                  },
                  {
                    name: 'Name',
                    selector: (row: any) => (row?.['name'] == null ? '' : row?.['name'])
                  },
                  {
                    name: 'IBAN',
                    selector: (row: any) => (row?.['iban'] == null ? '' : row?.['iban'])
                  },
                  {
                    name: 'Balance',
                    selector: (row: any) =>
                      row?.['balance'] == null ? '' : `${row?.['balance']} ${row?.['currency'].toUpperCase()} `
                  }
                ]}
                data={accountBalances}
                pagination
              ></DataTable>
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row>
        <Button onClick={handleKivraForm}>Send a kivra form</Button>
      </Row>
    </>
  );
};

export default PidDetails;
