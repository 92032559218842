import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import MerchantProfile from '../../../components/MerchantProfile';
import Button from '../../../shared-components/components/Button';
import { AdminPrefix, AdminUrl } from '../../../shared-components/configuration';

import style from './style.module.scss';

const ClientDetails = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();

  return (
    <Card className={style.card}>
      <>
        <Row>
          <Col sm={12} md={7}>
            <h1>Merchant Detail</h1>
          </Col>
          <Col sm={12} md={12} style={{ textAlign: 'right' }}>
            <Button
              variant="primary"
              onClick={() => navigate(`${AdminPrefix}/${AdminUrl.MERCHANTS_COUNTERPARTY}/${merchantId}`)}
            >
              Create new counterparty
            </Button>
            &nbsp;
            <Button
              variant="warning"
              onClick={() => navigate(`${AdminPrefix}/${AdminUrl.MERCHANTS_PENDING}/${merchantId}`)}
            >
              Show Pending transactions
            </Button>
          </Col>
        </Row>
        <MerchantProfile data={{ id: merchantId }} />
      </>
    </Card>
  );
};

export default ClientDetails;
