import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AccountSummary from '../../../components/AccountSummary';
import { MerchantTransaction } from '../../../types/grapql';
import { useBalances } from '../../../contexts/balances';
import { TRANSACTIONS_QUERY } from 'shared-components/queries/graphql';

const MerchantTransactionDetails = () => {
  //  const { merchantId } = useParams();
  const balances = useBalances();

  const [data, setData] = useState([]);
  const getQuery = useCallback(() => {
    App.sendMessage({
      service: 'graphql',
      data: {
        query: TRANSACTIONS_QUERY
      }
    });
  }, []);

  useEffect(function setupListener() {
    function handleListener(event: MessageEvent<any>) {
      if (event.data.system === 'graphql' && event.data.method === 'response' && event.data.data.data?.merchantTx) {
        setData(event.data.data.data.merchantTx);
      }
    }
    App.channel.addEventListener('message', handleListener);

    return function cleanupListener() {
      App.channel.removeEventListener('message', handleListener);
    };
  }, []);

  useEffect(() => {
    getQuery();
  }, [getQuery]);

  //  const [tableData, setData] = useState<TransactionHistoryRow[]>([]);

  const deposit = useMemo(() => {
    let amount = 0;
    for (const element of data as MerchantTransaction[]) {
      if (element.source?.toUpperCase() === 'MERCHANTPAYOUT') {
        amount += Number.parseFloat(element.amount ?? '0');
      }
    }
    return amount;
  }, [data]);

  const withdrawal = useMemo(() => {
    let amount = 0;

    for (const element of data as MerchantTransaction[]) {
      if (element.source?.toUpperCase() === 'WITHDRAWAL') {
        amount += Number.parseFloat(element.amount ?? '0');
      }
    }
    return amount;
  }, [data]);
  /*
  const tableData = useMemo(() => {
    let rows: TransactionHistoryRow[] = [];

    data?.forEach((element: MerchantTransaction) => {
      if (!element.id || element.currencyId !== 2 || element.source === 'intital_credit') {
        return;
      }
      rows = [
        ...rows,
        {
          source: element.source?.toUpperCase(),
          sourced: element.sourced,
          currency: `${element.amount} EUR`,
          description: element.description,
          clientRef: element.clientRef
        }
      ];
    });
    return rows;
  }, [data]);
*/
  const eurBalance = useMemo(() => {
    const broker = balances?.balances?.filter((account: any) => {
      return account.accountType === 'Broker';
    });
    if (!broker) {
      return;
    }
    const amount = broker[0]?.balance?.find((balance: any) => {
      return balance?.currencyName === 'EURX';
    })?.amount;
    return Number.parseFloat(amount).toFixed(2);
  }, [balances?.balances]);

  const eurPendingBalance = useMemo(() => {
    const suspended = balances?.balances?.filter((account: any) => {
      return account.accountType === 'Suspense';
    });
    if (!suspended) {
      return;
    }
    const amount = suspended[0]?.balance?.find((balance: any) => {
      return balance?.currencyName === 'EURX';
    })?.amount;
    return Number.isNaN(amount) ? (0).toFixed(2) : Number.parseFloat(amount).toFixed(2);
  }, [balances?.balances]);

  return (
    <>
      <AccountSummary
        withdrawals={`${withdrawal} EUR`}
        balance={`${eurBalance} EUR`}
        pendingBalance={`${eurPendingBalance} EUR`}
        deposits={`${deposit} EUR`}
      />
      <br />
    </>
  );
};

export default MerchantTransactionDetails;
/*
        <DataTable
          columns={columns}
          data={tableData ?? []}
          customStyles={customStyles}
          className="shadow p-3 mb-5 bg-white"
        />
*/
