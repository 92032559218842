import React, { createContext, useContext } from 'react';

import { NotifyType, Service } from '../configuration';
import { BaseProps } from '../utils/types';
import useSWSignal from '../hooks/useSWSignal';

const SWEventContext = createContext<any>({});

type UseSWEventProps = {
  onUpdateEvent?: (data: any) => void;
  onErrorEvent?: (data: any) => void;
  onAccountEvent?: (data: any) => void;
  onSignEvent?: (data: any) => void;
  onStateEvent?: (data: any) => void;
};

export const useSWCallbackUpdateEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.UPDATE,
    onNotify: callback
  });
};

export const useSWCallbackKYCEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.KYC,
    onNotify: callback
  });
};

export const useSWCallbackErrorEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.ERROR,
    onNotify: callback
  });
};

export const useSWCallbackAccountEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.ACCOUNT,
    onNotify: callback
  });
};

export const useSWCallbackSelectedEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.SELECTED,
    onNotify: callback
  });
};

export const useSWCallbackBankidEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.BANKID,
    onNotify: callback
  });
};

export const useSWCallbackSignEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.SIGN,
    onNotify: callback
  });
};

export const useSWCallbackStateEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.STATE,
    onNotify: callback
  });
};

export const useSWCallbackPaymentEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.PAYMENT,
    onNotify: callback
  });
};

export const useSWCallbackWithdrawalEvent = (callback?: (data: any) => void) => {
  return useSWSignal({
    service: Service.GRAPHQL,
    notifyType: NotifyType.WITHDRAWAL,
    onNotify: callback
  });
};

export const useSWCallbackEvents = ({
  onUpdateEvent,
  onErrorEvent,
  onAccountEvent,
  onSignEvent,
  onStateEvent
}: UseSWEventProps) => {
  const updateEvent = useSWCallbackUpdateEvent(onUpdateEvent);
  const errorEvent = useSWCallbackErrorEvent(onErrorEvent);
  const accountEvent = useSWCallbackAccountEvent(onAccountEvent);
  const signEvent = useSWCallbackSignEvent(onSignEvent);
  const stateEvent = useSWCallbackSignEvent(onStateEvent);

  return {
    updateEvent,
    errorEvent,
    accountEvent,
    signEvent,
    stateEvent
  };
};

export const useSWEvent = () => {
  const context = useContext(SWEventContext);
  if (!context) {
    throw new Error('UpdateEventContext not available');
  }
  return context;
};

const SWEventProvider = ({ children }: BaseProps) => {
  const { updateEvent, errorEvent, accountEvent, signEvent, stateEvent } = useSWCallbackEvents({});
  return (
    <SWEventContext.Provider value={{ updateEvent, errorEvent, accountEvent, signEvent, stateEvent }}>
      {children}
    </SWEventContext.Provider>
  );
};

export default SWEventProvider;
