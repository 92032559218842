import { Button, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

import clsx from 'clsx';

import { environment, settleHost } from '../../../shared-components/configuration';

import style from './style.module.scss';

const Landing = () => {
  useEffect(() => {
    if (environment.startsWith('admin')) {
      window.location.href = '/admin';
    }
  });

  return (
    <>
      <center>
        <div className={style.container}>
          <h1 className={style.header}>
            Settlement
            <br /> has never been easier
          </h1>
          <Row className="d-flex justify-content-center">
            <Button variant="primary" className={clsx(style.button, style.contact_sale_button)} href="/contact-sales">
              Contact Sales
            </Button>
            <Button variant="outline-primary" className={clsx(style.button, style.settle_button)} href={settleHost}>
              Get settled
            </Button>
          </Row>
        </div>
      </center>
    </>
  );
};

export default Landing;
